import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Twitter from "../images/twitter-brands.svg"
import Fb from "../images/facebook-f-brands.svg"
import Insta from "../images/instagram-brands.svg"
import Li from "../images/linkedin-in-brands.svg"

export default () => {
  const {
    file: {
      childMarkdownRemark: { frontmatter },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/contact-success.md" }) {
        childMarkdownRemark {
          frontmatter {
            title
            meta {
              title
              description
            }
          }
        }
      }
    }
  `)

  return (
    <Layout meta={frontmatter.meta}>
      <section className="sub-hero-section">
        <div className="container">
          <div className="row">
            <div className="beige-bg with-radius">
              <div className="register-box-title flex flex-ac">
                <h1>{frontmatter.title}</h1>
              </div>
              <div className="grid-2-col">
                <div className="register-box-text">
                  <p className="lead">
                    We'll get back to you as soon as possible. In the meantime
                    follow our updates on social media and on our{" "}
                    <Link to="/blog/">blog</Link> or{" "}
                    <Link to="/register/">register your interest</Link> to get
                    early access to our digital products.
                  </p>
                </div>
                <div className="social-success flex flex-jc flex-wrap">
                  <a href="https://twitter.com/CTI_Pty_Ltd">
                    <img src={Twitter} alt="Twitter icon" />
                  </a>
                  <a href="https://www.facebook.com/Creative-Thinking-Institute-100202631717583/">
                    <img src={Fb} alt="Facebook icon" />
                  </a>
                  <a href="https://www.instagram.com/creativethinkinginstitute/">
                    <img src={Insta} alt="Instagram icon" />
                  </a>
                  <a href="http://linkedin.com/company/creative-thinking-institute">
                    <img src={Li} alt="Linkedin icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
